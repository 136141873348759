import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ResetDeliveriesV2State,
  SelectTabIndex,
} from '../actions/deliveries-shared.actions';
import { Injectable } from '@angular/core';
import { DeliveriesV2Tabs } from '../model/deliveries.model';

export interface DeliveriesV2StateModel {
  selectedTabIndex: number;
}

@State<DeliveriesV2StateModel>({
  name: 'deliveriesV2State',
  defaults: {
    selectedTabIndex: DeliveriesV2Tabs.overdue,
  },
})
@Injectable()
export class DeliveriesV2State {
  @Selector()
  static selectedTabIndex(state: DeliveriesV2StateModel) {
    return state.selectedTabIndex;
  }

  @Action(SelectTabIndex, { cancelUncompleted: true })
  selectTabIndex(
    ctx: StateContext<DeliveriesV2StateModel>,
    payload: SelectTabIndex
  ) {
    ctx.patchState({
      selectedTabIndex: payload.index,
    });
  }

  @Action(ResetDeliveriesV2State, { cancelUncompleted: true })
  resetDeliveriesV2State(ctx: StateContext<DeliveriesV2StateModel>) {
    ctx.patchState({
      selectedTabIndex: DeliveriesV2Tabs.overdue,
    });
  }
}
